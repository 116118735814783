import React, { useContext, useEffect } from 'react'
import { ButtonV3, LoadingProvi } from '@provi/provi-components'
import { history } from '../../../navigation/history'
import { TemplateDeclarationContext } from '../../../components/template-declaration/context'
import { Container, Title, Text, Wrapper } from './style'
import rocket_illustration_2 from '../../../assets/svg/rocket-illustration-2.svg'

import { LOCALSTORAGE_TOKENS } from '../../../constants'
import { postDataScreenCurrent } from '../../../services/isa'

export const DeclarationSuccess = () => {
  const { isMobile, isLoading, setDataModal } = useContext(TemplateDeclarationContext)

  useEffect(() => {
    setDataModal(null)
  }, [setDataModal])

  return (
    <>
      {isLoading && <LoadingProvi isLoadingDark={false} />}
      <Container>
        <Wrapper>
          <Title>Declaração enviada ;)</Title>
          <Text>
            Agora é com a gente! Recebemos a sua declaração e vamos <br />
            analisar com cuidado todas as informações.
          </Text>

          <Text>
            Se tivermos alguma dúvida ou precisarmos de mais documentos, entraremos em contato com você. Fique de olho!
          </Text>

          <ButtonV3
            marginHorizontal={0}
            marginVertical={0}
            text="Voltar para o painel"
            color="white"
            onClick={async () => {
              history.push('/dashboard/declaracoes-isa')

              const crid_isa = localStorage.getItem(LOCALSTORAGE_TOKENS.crid_isa)
              await postDataScreenCurrent(crid_isa, {})
            }}
          />
        </Wrapper>
        {!isMobile && (
          <img
            height="90%"
            style={{
              position: 'fixed',
              right: 0,
              bottom: 0
            }}
            src={rocket_illustration_2}
          />
        )}
      </Container>
    </>
  )
}
