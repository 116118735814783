import React, { useContext } from 'react'

import { Hamburguer } from '../../hamburguer'
import { TemplateContext } from '../../template/context'

import { CaretDoubleLeft } from '../../../assets/svg/caret-double-left'
import { LogoProviV3 } from '../../../assets/svg/logo-provi-v3'

import { REDIRECT_URLS } from '../../../constants'
import { useMenu } from '../hooks'
import { MenuOption } from '../menu-option'
import { Link, MenuOptions, NavTitle, Notification, Top } from '../styles'
import { Container, ContainerHamburguer, Content, ContentHeader, LogoutMobileContainer } from './styles'

export const MobileContainer = () => {
  const { typeDash, showExtraInfo, selectCrid, menuOptions, isSubMenuToggled, notifications } = useContext(TemplateContext)
  const { isMenuMobileOpened, handleMenuMobileOpen, handleSubMenuToggle } = useMenu({
    typeDash,
    selectCrid,
    showExtraInfo
  })

  return (
    <Container>
      <Top>
        <ContainerHamburguer>
          <Hamburguer onClick={() => handleMenuMobileOpen()} />
          {notifications.length > 0 && <Notification isMobile>{notifications.length}</Notification>}
        </ContainerHamburguer>
      </Top>

      {isMenuMobileOpened && (
        <>
          <Content isMenuMobileOpened={isMenuMobileOpened}>
            <ContentHeader>
              <Link to="/">
                <LogoProviV3 />
              </Link>
              <button type="button" onClick={() => handleMenuMobileOpen()}>
                <CaretDoubleLeft />
              </button>
            </ContentHeader>

            <MenuOptions>
              <NavTitle>
                <h4>PAINEL DO ALUNO</h4>
              </NavTitle>
              {menuOptions
                ?.filter(item => Boolean(item))
                ?.map(option => (
                  <MenuOption
                    key={option?.id}
                    option={option}
                    isSubMenuToggled={isSubMenuToggled}
                    handleSubMenuToggle={handleSubMenuToggle}
                  />
                ))}
              <LogoutMobileContainer>
                <a href={REDIRECT_URLS.passwordless}>Sair</a>
              </LogoutMobileContainer>
            </MenuOptions>
          </Content>
        </>
      )}
    </Container>
  )
}
