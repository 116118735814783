import React, { useContext } from 'react'
import { Form, Title, ButtonV3, SubTitle, Input } from '@provi/provi-components'
import { TemplateDeclarationContext } from '../../../components/template-declaration/context'
import { TemplateDeclaration } from '../../../components/template-declaration'
import { Container, ContainerOptions, Option, ButtonsContainer } from './style'
import { useIndicationPartner } from './hooks'

export const IndicationPartner = () => {
  const { goToNextScreen, incomeStatementPath } = useContext(TemplateDeclarationContext)
  const [
    setComments,
    selectedOption,
    onSelectOption,
    onSubmit,
    isButtonDisabled,
    value,
    optionsIndication
  ] = useIndicationPartner(goToNextScreen, incomeStatementPath)

  return (
    <TemplateDeclaration subTitle="Avaliação do Curso">
      <Container>
        {incomeStatementPath?.readIndicationPartner?.partner?.name && (
          <Form onSubmit={onSubmit}>
            <Title
              text={`Em uma escala de 1 a 10, o quanto você recomenda a ${incomeStatementPath?.readIndicationPartner?.partner?.name}?`}
            />
            <ContainerOptions>
              {optionsIndication.map(item => {
                return (
                  <Option key={item.id} onClick={() => onSelectOption(item)} active={selectedOption === item.id}>
                    {item.text}
                  </Option>
                )
              })}
            </ContainerOptions>
            <Title text="Aproveite e conte pra gente o que te motivou a dar essa nota :)" />
            <SubTitle text="Queremos acompanhar como está sendo a sua experiência. Por isso, vamos te fazer essa pergunta ao longo do curso." />

            <Input
              margin="normal"
              type="text"
              multiline
              placeholder="Escreva aqui..."
              onChange={e => setComments(e.target.value)}
              value={value}
              rowsMax={12}
              className="input"
            />
            <ButtonsContainer>
              <ButtonV3 marginHorizontal={0} buttonProps={{ type: 'submit' }} disabled={isButtonDisabled} text="Avançar" />
            </ButtonsContainer>
          </Form>
        )}
      </Container>
    </TemplateDeclaration>
  )
}
