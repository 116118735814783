import React, { useContext } from 'react'
import { ButtonV3, theme } from '@provi/provi-components'
import { currencyParser } from '../../../../utils'
import { IconDownload } from '../../../../assets/svg/icon-download'
import { TemplateContext } from '../../../../components/template/context'
import {
  Br,
  ItemInvoice,
  Left,
  Text,
  Footer,
  TextTitle,
  TextSimple,
  Right,
  Status,
  Header,
  TextButton,
  InformationText,
  Center
} from './styles'
import { Hr } from '../../../../components/horizontal-row'

export const CardAvailable = ({ item, getInvoice }) => {
  const { isMobile } = useContext(TemplateContext)
  return (
    <ItemInvoice>
      <Header>
        <Left>
          <TextTitle>{currencyParser(item.price || 0, 'R$')}</TextTitle>
          <TextSimple>Vencimento: {item.date}</TextSimple>
        </Left>
        <Right>
          <Text>Fatura aberta</Text>
          <Status color={theme.colors.yellow900} />
        </Right>
      </Header>
      <Footer>
        <Left>
          {isMobile ? (
            <ButtonV3 onClick={() => getInvoice(item)}>
              <TextButton>Pagar agora</TextButton>
              {!isMobile && <IconDownload />}
            </ButtonV3>
          ) : (
            <ButtonV3
              color="light"
              marginVertical={0}
              marginHorizontal={0}
              onClick={() => getInvoice(item)}
              text="Pagar agora"
            />
          )}
        </Left>
        <Right>
          <Text>{item.isUpfront ? 'Entrada' : `${item.number}/${item.amountOfItems}`}</Text>
        </Right>
      </Footer>
      {item?.renegotiationInvoice && (
        <Center>
          <Hr />
          <InformationText>
            • Essa fatura deve ser paga para efetivar a renegociação do financiamento <Br />
            • Não é possivel gerar uma segunda via dessa fatura
            <Br />
          </InformationText>
        </Center>
      )}
    </ItemInvoice>
  )
}
