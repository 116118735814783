import React, { useContext } from 'react'
import {
  Container,
  Label,
  WrapperForm,
  InputMasks,
  ContainerItemForm,
  IncomeTypeDescription,
  ObservationsContainer
} from './style'
import _ from 'lodash'
import { Title, ButtonV3, Form } from '@provi/provi-components'
import { TemplateDeclarationContext } from '../../../components/template-declaration/context'
import { TemplateDeclaration } from '../../../components/template-declaration'
import { useExtraIncomeValue } from './hooks'

import { getPreviousMonthName } from '../../../utils'

export const ExtraIncomeValue = () => {
  const { goToNextScreen, incomeStatementPath, setDataModal } = useContext(TemplateDeclarationContext)
  const [onSubmit, onValueChange, values, isButtonDisabled] = useExtraIncomeValue(
    goToNextScreen,
    incomeStatementPath,
    setDataModal
  )

  return (
    <TemplateDeclaration subTitle="Outras Rendas">
      <Container isMarginDesktop>
        <Title text={`Quanto você ganhou no mês de ${getPreviousMonthName()}?`} />
        <Form onSubmit={onSubmit}>
          <ContainerItemForm>
            {incomeStatementPath &&
              incomeStatementPath.readExtraIncomeValue &&
              incomeStatementPath.readExtraIncomeValue.length > 0 &&
              incomeStatementPath.readExtraIncomeValue.map(item => (
                <WrapperForm key={item.id}>
                  <Label>{item.displayName}</Label>
                  <IncomeTypeDescription> {item.description} </IncomeTypeDescription>
                  <InputMasks
                    decimalSeparator=","
                    thousandSeparator="."
                    prefix="R$ "
                    type="text"
                    placeholder="Ex: R$1.000,00"
                    id={item.id}
                    onChangeEvent={(a, _, value) => onValueChange(a, _, value, values[item.internalName].id, item.internalName)}
                    value={!_.isEmpty(values) && values[item.internalName].value}
                  />
                  <ObservationsContainer>
                    <label htmlFor="observations">
                      <strong>Observações</strong> (opcional)
                    </label>
                    <input name="observations" placeholder="Escreva aqui" type="text" />
                  </ObservationsContainer>
                </WrapperForm>
              ))}
          </ContainerItemForm>

          <ButtonV3 marginHorizontal={0} buttonProps={{ type: 'submit' }} disabled={isButtonDisabled} text="Avançar" />
        </Form>
      </Container>
    </TemplateDeclaration>
  )
}
