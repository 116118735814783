import styled from 'styled-components'
import { theme } from '@provi/provi-components'

export const Container = styled.div`
  box-sizing: border-box;
  padding-left: 114px;
  padding-right: 20px;
  height: 100vh;
  background: linear-gradient(180deg, rgba(0, 110, 171, 1) 0%, rgba(95, 196, 225, 1) 100%);
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: 5%;

  ${theme.breakpoints.down('lg')} {
    font-size: 24px;
  }

  ${theme.breakpoints.down('md')} {
    padding-left: 20px;
  }
`

export const Title = styled.p`
  font-family: CenturyGothicBold, sans-serif;
  color: #a9ecff;
  font-size: 48px;
  line-height: 72px;
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
  }
`

export const Text = styled.p`
  font-family: 'CenturyGothic', sans serif;
  font-size: 24px;
  line-height: 32px;
  color: ${theme.colors.white100};
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}px`};
  ${theme.breakpoints.down('md')} {
    font-size: 16px;
    line-height: 24px;
  }
`

export const Wrapper = styled.div`
  width: 640px;

  ${theme.breakpoints.down('md')} {
    width: 100%;
  }

  div {
    margin-top: 64px;
  }
  .MuiButton-label {
    font-size: 14px;

    svg {
      display: none;
    }
  }
`
