import React, { useContext } from 'react'
import { UploadBox, Title, ButtonV3 } from '@provi/provi-components'
import { history } from '../../../navigation/history'
import { Template } from '../../../components/template'
import { WebcamWrapper } from '../../../components/webcam'
import { TemplateContext } from '../../../components/template/context'
import { TemplateProviSignContext } from '../../../components/template-provisign/context'
import { MaxWidth } from '../../../components/max-width'
import { useSelfie } from './hooks'
import { Wrapper, WrapperFiles, Text } from './styles'

import img_selfie from '../../../assets/svg/img_selfie.svg'
import img_selfie_disabled from '../../../assets/svg/img_selfie_disabled.svg'

const OPTIONS_DROPZONE = 'image/jpeg, image/png'

export const Selfie = props => {
  const { setObjectValidation, objectValidation, setTypeUser } = useContext(TemplateProviSignContext)
  const { isMobile, setIsLoading } = useContext(TemplateContext)
  const [
    preview,
    onSubmit,
    isButtonDisabled,
    numberOfFiles,
    filesNotUpload,
    setFilesNotUpload,
    setIsButtonDisabled,
    setIsWebcam,
    isWebcam,
    setPreview,
    setNumberOfFiles,
    numberOfFilesInitial
  ] = useSelfie(setIsLoading, props, setObjectValidation, objectValidation, setTypeUser)
  return (
    <Template isShowMenu>
      <MaxWidth>
        <Wrapper>
          <Title text="Envie uma selfie" />

          {!isMobile && (
            <WrapperFiles>
              <UploadBox
                width={216}
                preview={preview}
                setPreview={setPreview}
                setFilesNotUpload={setFilesNotUpload}
                filesNotUpload={filesNotUpload}
                setNumberOfFiles={setNumberOfFiles}
                numberOfFiles={numberOfFiles}
                numberOfFilesInitial={numberOfFilesInitial}
                isWebcam={isWebcam}
                image={isWebcam ? img_selfie_disabled : img_selfie}
                options={OPTIONS_DROPZONE}
              />
              <Text>ou</Text>
              <WebcamWrapper
                file={preview}
                setPreview={setPreview}
                setIsButtonDisabled={setIsButtonDisabled}
                filesNotUpload={filesNotUpload}
                setFilesNotUpload={setFilesNotUpload}
                disabled={numberOfFiles > 0}
                img={numberOfFiles > 0 ? img_selfie_disabled : img_selfie}
                setIsWebcam={setIsWebcam}
              />
            </WrapperFiles>
          )}

          {isMobile && (
            <UploadBox
              preview={preview}
              setPreview={setPreview}
              setFilesNotUpload={setFilesNotUpload}
              filesNotUpload={filesNotUpload}
              setNumberOfFiles={setNumberOfFiles}
              numberOfFiles={numberOfFiles}
              numberOfFilesInitial={numberOfFilesInitial}
              isWebcam={isWebcam}
              image={isWebcam ? img_selfie_disabled : img_selfie}
              options={OPTIONS_DROPZONE}
            />
          )}

          <ButtonV3
            marginHorizontal={0}
            buttonProps={{ type: 'submit' }}
            text="Avançar"
            onClick={onSubmit}
            disabled={isButtonDisabled}
            textLink={{ text: 'Voltar', onClick: () => history.push('/dashboard/faturas/abertas') }}
          />
        </Wrapper>
      </MaxWidth>
    </Template>
  )
}
