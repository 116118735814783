import React, { useContext } from 'react'
import { ButtonV3, Title, Form, UploadBox, SubHeaderDesktop } from '@provi/provi-components'
import { TemplateDeclarationContext } from '../../../components/template-declaration/context'
import { TemplateDeclaration } from '../../../components/template-declaration'
import img_upload from '../../../assets/svg/preview_upload_isa.svg'
import { useIncomeProof } from './hooks'
import { Container, SubTitle } from './style'

const OPTIONS_DROPZONE = 'image/jpeg, image/png, application/pdf'

export const IncomeProof = () => {
  const { goToNextScreen, incomeStatementPath, setIsLoading, setIsShowModal, isMobile, setDataModal } = useContext(
    TemplateDeclarationContext
  )
  const [
    onSubmit,
    isButtonDisabled,
    numberOfFiles,
    preview,
    setPreview,
    setFilesNotUpload,
    filesNotUpload,
    setNumberOfFiles,
    numberOfFilesInitial,
    title,
    example
  ] = useIncomeProof(goToNextScreen, incomeStatementPath, setIsLoading, setDataModal, setIsShowModal)
  return (
    <TemplateDeclaration subTitle="Comprovantes">
      <Container>
        <Form onSubmit={onSubmit}>
          {isMobile ? (
            <>
              <Title text={title} />
              <SubTitle>
                Alguns exemplos de documentos:
                <ul>
                  {example.map(text => (
                    <li key={text}>{text}</li>
                  ))}
                </ul>
              </SubTitle>
            </>
          ) : (
            <>
              <SubHeaderDesktop title={title} handleModal={() => setIsShowModal(true)} isShowButtonHelpModal={false} />
              <SubTitle>
                Para te ajudar, os comprovantes que aceitamos são:
                <ul>
                  {example.map(text => (
                    <>
                      <li key={text}>{text}</li>
                    </>
                  ))}
                </ul>
              </SubTitle>
            </>
          )}
          <UploadBox
            preview={preview}
            setPreview={setPreview}
            setFilesNotUpload={setFilesNotUpload}
            filesNotUpload={filesNotUpload}
            setNumberOfFiles={setNumberOfFiles}
            numberOfFiles={numberOfFiles}
            image={img_upload}
            numberOfFilesInitial={numberOfFilesInitial}
            options={OPTIONS_DROPZONE}
            width={289}
          />

          <ButtonV3 marginHorizontal={0} buttonProps={{ type: 'submit' }} text="Avançar" disabled={isButtonDisabled} />
        </Form>
      </Container>
    </TemplateDeclaration>
  )
}
