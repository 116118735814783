import React, { useState, useContext } from 'react'
import { ButtonV3, Modal } from '@provi/provi-components'

import { TemplateDeclaration } from '../../../components/template-declaration'
import { TemplateDeclarationContext } from '../../../components/template-declaration/context'
import { CloseModal } from '../../../assets/svg/close-modal'
import { getPreviousMonthName } from '../../../utils'
import { TemplateContext } from '../../../components/template/context'

import { Container, ButtonsContainer, DoubtMenuContainer, TextModal, Text } from './styles'

export const HadIncomePerWork = () => {
  const { goToNextScreen } = useContext(TemplateDeclarationContext)
  const [isDoubtMenuContainerToggled, setIsDoubtMenuToggled] = useState(false)
  const { isMobile } = useContext(TemplateContext)
  const [isShowModal, setIsShowModal] = useState(false)

  return (
    <TemplateDeclaration subTitle="Renda de Trabalho">
      <Container>
        <Text>
          No mês de <b>{getPreviousMonthName().toLocaleLowerCase()}</b>, você <b>recebeu dinheiro</b> por algum trabalho que
          fez?
        </Text>
        <ButtonsContainer>
          <ButtonV3 color="light" text="Não recebi" onClick={() => goToNextScreen(false)} />
          <ButtonV3 icon text="Recebi" onClick={() => goToNextScreen(true)} />
        </ButtonsContainer>

        <DoubtMenuContainer isDoubtMenuContainerToggled={isDoubtMenuContainerToggled}>
          <div className="doubt-menu-content">
            <button
              className="toggleDoubtMenu"
              onClick={isMobile ? () => setIsShowModal(true) : () => setIsDoubtMenuToggled(true)}
            >
              Estou em dúvida
            </button>
            <button className="closeDoubtMenu" type="button" onClick={() => setIsDoubtMenuToggled(false)}>
              <CloseModal variant="blue" />
            </button>
          </div>

          <div className="doubt-text-container">
            Consideramos como renda de trabalho toda remuneração <br />
            que você recebeu por uma atividade ou serviço prestado, seja <br />
            como empregado, autônomo, trabalhador informal, <br />
            empresário, estagiário ou funcionário público.
          </div>

          {isShowModal && (
            <Modal title="Estou em dúvida" onDismiss={() => setIsShowModal(false)}>
              <TextModal>
                Consideramos como renda de trabalho toda remuneração que você recebeu por uma atividade ou serviço prestado,
                seja como empregado, autônomo, trabalhador informal, empresário, estagiário ou funcionário público.
              </TextModal>
            </Modal>
          )}
        </DoubtMenuContainer>
      </Container>
    </TemplateDeclaration>
  )
}
