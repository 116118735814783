import React from 'react'
import { cnpj as cnpjValidator } from 'cpf-cnpj-validator'
import { withFormik } from 'formik'
import { ButtonV3, InputMasks, TextErrorInput, showToast } from '@provi/provi-components'
import { isValidCpf } from '../../../utils/validators'
import { logger } from '../../../utils/logger'
import { validateCPF, validateCNPJ } from '../../../services/provi-sign'
import { Form } from './styles'

const Formik = props => {
  const { handleChange, values, errors, handleBlur, handleSubmit, objectValidation } = props
  return (
    <Form onSubmit={handleSubmit}>
      {objectValidation && objectValidation.isCPF ? (
        <>
          <InputMasks
            mask="999.999.999-99"
            margin="normal"
            id="cpf"
            type="tel"
            placeholder={'Ex: 111.222.333-44'}
            onChange={handleChange('cpf')}
            onBlur={handleBlur('cpf')}
            error={!!errors.cpf}
            value={values.cpf}
            autoFocus
          />
          {values.cpf && !isValidCpf(values && values.cpf.replace(/[^\d]+/g, '')) && <TextErrorInput text="CPF inválido" />}
        </>
      ) : (
        <>
          <InputMasks
            mask="99.999.999/9999-99"
            margin="normal"
            id="cnpj"
            type="tel"
            placeholder={'Ex: 11.222.333/4444-55'}
            onChange={handleChange('cnpj')}
            onBlur={handleBlur('cnpj')}
            error={!!errors.cnpj}
            value={values.cnpj}
            autoFocus
          />
          {values.cnpj && !cnpjValidator.isValid(values && values.cnpj && values.cnpj.replace(/[^\d]+/g, '')) && (
            <TextErrorInput text="CNPJ inválido" />
          )}
        </>
      )}

      <ButtonV3
        marginHorizontal={0}
        buttonProps={{ type: 'submit' }}
        disabled={
          !isValidCpf(values && values.cpf && values.cpf.replace(/[^\d]+/g, '')) &&
          !cnpjValidator.isValid(values && values.cnpj && values.cnpj.replace(/[^\d]+/g, ''))
        }
        text="Avançar"
      />
    </Form>
  )
}

export const FormFormik = withFormik({
  validateOnBlur: false,
  validateOnChange: true,
  enableReinitialize: true,
  mapPropsToValues: props => ({ cpf: props.data || '' }),
  handleSubmit: async ({ cpf, cnpj }, { props: { onSubmit, setIsLoading, objectValidation, proviContractId, typeUser } }) => {
    try {
      setIsLoading(true)

      const body = {
        data: cnpj || cpf,
        ip: null,
        device: navigator && navigator.userAgent,
        ProviContractId: proviContractId
      }
      objectValidation.isCPF ? await validateCPF(body, typeUser) : await validateCNPJ(body, typeUser)
      onSubmit()
    } catch (err) {
      logger(err)
      if (err.response && err.response.data && err.response.data.error) {
        showToast(err.response.data.error.message)
        return
      }
      showToast()
    } finally {
      setIsLoading(false)
    }
  }
})(Formik)
