import React, { useContext } from 'react'
import {
  Container,
  Label,
  WrapperForm,
  InputMasks,
  ContainerItemForm,
  IncomeTypeDescription,
  ObservationsContainer,
  ContainerInputs
} from './style'
import _ from 'lodash'
import { Title, ButtonV3, Form } from '@provi/provi-components'
import { TemplateDeclarationContext } from '../../../components/template-declaration/context'
import { TemplateDeclaration } from '../../../components/template-declaration'
import { useWorkIncomeValue } from './hooks'

import { getPreviousMonthName } from '../../../utils'
import { MaskedCnpj } from '../../../components/MaskedCnpj'

export const WorkIncomeValue = () => {
  const { goToNextScreen, incomeStatementPath, setDataModal } = useContext(TemplateDeclarationContext)
  const [formatInternalName, onSubmit, onValueChange, values, isButtonDisabled] = useWorkIncomeValue(
    goToNextScreen,
    incomeStatementPath,
    setDataModal
  )

  return (
    <TemplateDeclaration subTitle="Rendas de Trabalho">
      <Container>
        <Title text={`Quanto você ganhou no mês de ${getPreviousMonthName()}?`} />
        <Form onSubmit={onSubmit}>
          <ContainerItemForm>
            {incomeStatementPath &&
              incomeStatementPath.readWorkIncomeValue &&
              incomeStatementPath.readWorkIncomeValue.length > 0 &&
              incomeStatementPath.readWorkIncomeValue.map(item => (
                <WrapperForm key={item.id}>
                  <Label>{formatInternalName(item)}</Label>
                  <IncomeTypeDescription>{item.description}</IncomeTypeDescription>
                  <ContainerInputs>
                    <InputMasks
                      decimalSeparator=","
                      thousandSeparator="."
                      prefix="R$ "
                      type="text"
                      placeholder="Ex: R$1.000,00"
                      id={item.id}
                      width="146px"
                      onChangeEvent={(a, __, value) =>
                        onValueChange({
                          value,
                          id: values[item.internalName].id,
                          name: item.internalName,
                          cnpj: values[item.internalName].cnpj,
                          observations: values[item.internalName].observations
                        })
                      }
                      value={!_.isEmpty(values) && values[item.internalName].value}
                    />

                    {item.internalName === 'mei' || item.internalName === 'me' ? (
                      <MaskedCnpj
                        mask="99.999.999-9999/99"
                        placeholder="Ex: CNPJ 00.000.000-0000/00"
                        onChange={({ target }) =>
                          onValueChange({
                            id: values[item.internalName].id,
                            value: values[item.internalName].value,
                            name: item.internalName,
                            cnpj: target.value,
                            observations: values[item.internalName].observations
                          })
                        }
                        value={!_.isEmpty(values) && values[item.internalName].cnpj}
                      />
                    ) : null}
                  </ContainerInputs>
                  <ObservationsContainer>
                    <label htmlFor="observations">
                      <strong>Observações</strong> (opcional)
                    </label>
                    <input
                      name="observations"
                      placeholder="Escreva aqui"
                      type="text"
                      onChange={({ target }) =>
                        onValueChange({
                          id: values[item.internalName].id,
                          value: values[item.internalName].value,
                          name: item.internalName,
                          cnpj: values[item.internalName].cnpj,
                          observations: target.value
                        })
                      }
                      value={!_.isEmpty(values) && values[item.internalName].observations}
                    />
                  </ObservationsContainer>
                </WrapperForm>
              ))}
          </ContainerItemForm>
          <ButtonV3 marginHorizontal={0} buttonProps={{ type: 'submit' }} disabled={isButtonDisabled} text="Avançar" />
        </Form>
      </Container>
    </TemplateDeclaration>
  )
}
