import React, { createElement, useContext } from 'react'

import { history } from '../../../navigation/history'
import { TemplateContext } from '../../template/context'

import { CaretDoubleLeft } from '../../../assets/svg/caret-double-left'
import { CaretDoubleRight } from '../../../assets/svg/caret-double-right'
import { LogoProviV3 } from '../../../assets/svg/logo-provi-v3'
import { Share } from '../../../assets/svg/share'

import { REDIRECT_URLS } from '../../../constants'
import { useMenu } from '../hooks'
import { MenuOption } from '../menu-option'
import * as S from '../styles'
import { Container, Content, ContentHeader, IconOption, Link, MenuIconsOptions } from './styles'

export const DesktopContainer = () => {
  const {
    typeDash,
    showExtraInfo,
    selectCrid,
    isMenuDesktopOpened,
    setIsMenuDesktopOpened,
    menuOptions,
    notifications
  } = useContext(TemplateContext)
  const { handleIsMenuDesktopOpened, handleSubMenuToggle } = useMenu({
    typeDash,
    selectCrid,
    showExtraInfo,
    isMenuDesktopOpened,
    setIsMenuDesktopOpened
  })

  const { pathname } = history.location

  return (
    <Container isMenuToggled={isMenuDesktopOpened}>
      <Content>
        <ContentHeader isMenuToggled={isMenuDesktopOpened}>
          <S.Link to="/">
            <LogoProviV3 />
          </S.Link>
          <button type="button" onClick={handleIsMenuDesktopOpened}>
            {isMenuDesktopOpened ? <CaretDoubleLeft /> : <CaretDoubleRight />}
          </button>
        </ContentHeader>

        {isMenuDesktopOpened ? (
          <S.MenuOptions>
            <S.NavTitle>
              <h4>PAINEL DO ALUNO</h4>
            </S.NavTitle>

            {menuOptions
              ?.filter(item => Boolean(item))
              ?.map(option => (
                <MenuOption key={option?.id} option={option} handleSubMenuToggle={handleSubMenuToggle} />
              ))}

            <S.LogoutContainer>
              <a href={REDIRECT_URLS.passwordless}>Sair</a>
            </S.LogoutContainer>
          </S.MenuOptions>
        ) : (
          <MenuIconsOptions>
            {menuOptions?.map((option, index) => (
              <IconOption isMenuDesktopOpened={isMenuDesktopOpened} active={pathname === option?.route} key={index}>
                <Link icon="true" to={option?.route} title={option?.text}>
                  {createElement('svg', { href: option?.route }, option?.icon)}
                  {notifications.length > 0 && option?.text === 'Notificações' && (
                    <S.Notification isIcon>{notifications.length}</S.Notification>
                  )}
                </Link>
              </IconOption>
            ))}
            <S.LogoutContainer>
              <a href={REDIRECT_URLS.passwordless}>
                <Share />
              </a>
            </S.LogoutContainer>
          </MenuIconsOptions>
        )}
      </Content>
    </Container>
  )
}
