import { ButtonV3 } from '@provi/provi-components'
import React, { useContext, useMemo } from 'react'
import phoneMockup from '../../../assets/images/phone-mockup.png'
import { MaxWidth } from '../../../components/max-width'
import { Template } from '../../../components/template'
import { TemplateContext } from '../../../components/template/context'
import { useInvoices } from './hooks'
import { InvoiceCoursing } from './invoice-coursing'
import {
  ButtonContainer,
  ButtonGroup,
  ButtonItemLeft,
  ButtonItemRight,
  ContainerImage,
  ContainerInvoices,
  ContainerNewPortal,
  ContainerNewPortalContainer,
  ContainerNewPortalText,
  ContainerNewPortalTitle,
  Wrapper
} from './styles'

const renderInvoiceCoursing = (item, getInvoice) => {
  const obj = {
    pending: <InvoiceCoursing.CardAvailable item={item} key={item.id} getInvoice={getInvoice} />,
    paid: <InvoiceCoursing.CardPaid item={item} key={item.id} getInvoice={getInvoice} />,
    delayed: <InvoiceCoursing.CardDelayed item={item} key={item.id} getInvoice={getInvoice} />,
    locked: <InvoiceCoursing.CardLocked item={item} key={item.id} />,
    delayed_blocked: <InvoiceCoursing.CardDelayedBlocked item={item} key={item.id} />
  }
  return obj[item.status]
}

const renderButtonGroup = (handleToggle, toggle) => {
  return (
    <ButtonGroup>
      <ButtonItemLeft onClick={() => handleToggle('open')} active={toggle === 'open'}>
        Em aberto
      </ButtonItemLeft>
      <ButtonItemRight onClick={() => handleToggle('paid')} active={toggle === 'paid'}>
        Pagas
      </ButtonItemRight>
    </ButtonGroup>
  )
}

export const Invoices = () => {
  const { setMenuActive, typeDash, setIsBack, setIsLoading, selectCrid, extraInfos } = useContext(TemplateContext)

  const [data, invoice, info, toggle, handleToggle, getInvoice] = useInvoices(
    setMenuActive,
    setIsBack,
    setIsLoading,
    selectCrid,
    typeDash
  )

  const getOptions = useMemo(() => {
    if (toggle === 'paid') {
      return invoice?.filter(item => item.status === 'paid')
    } else {
      return invoice?.filter(item => item.status !== 'paid')
    }
  }, [toggle, invoice])

  const options = getOptions

  return (
    <Template>
      <MaxWidth>
        <Wrapper>
          {extraInfos?.isWithDifferentAgent ? (
            <ContainerNewPortal>
              <ContainerImage src={phoneMockup} />

              <ContainerNewPortalContainer>
                <ContainerNewPortalTitle>Seu jeito de pagar suas faturas mudou! ;)</ContainerNewPortalTitle>
                <ContainerNewPortalText>
                  Para ter acesso às suas faturas, acesse o nosso novo portal e realize o primeiro login:
                </ContainerNewPortalText>
                <ContainerNewPortalText>
                  <ul>
                    <li>
                      <strong>Insira seu CPF</strong> no campo indicado
                    </li>
                    <li>
                      A senha será <strong>somente os números da sua data de nascimento</strong>, sem barras ou traços
                    </li>
                    <li>Após o primeiro login, você poderá alterar sua senha</li>
                  </ul>
                </ContainerNewPortalText>
                <ButtonContainer>
                  <ButtonV3
                    text="Acessar novo portal"
                    buttonProps={{
                      href: 'https://principiapay.alunos.principia.net',
                      target: '_blank',
                      rel: 'noopener noreferrer'
                    }}
                  />
                </ButtonContainer>
              </ContainerNewPortalContainer>
            </ContainerNewPortal>
          ) : (
            renderButtonGroup(handleToggle, toggle)
          )}

          <ContainerInvoices>{options.map(item => renderInvoiceCoursing(item, getInvoice))}</ContainerInvoices>
        </Wrapper>
      </MaxWidth>
    </Template>
  )
}
