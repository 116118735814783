import React, { useContext } from 'react'
import { ButtonV3, Form, Title } from '@provi/provi-components'
import { TemplateDeclarationContext } from '../../../components/template-declaration/context'
import { TemplateDeclaration } from '../../../components/template-declaration'
import { Option } from '../../../components/option'
import { Container, ContainerOptions, SubTitle, FormContainer } from './style'
import { useExtraIncomeType } from './hooks'
import { getPreviousMonthName } from '../../../utils'

export const ExtraIncomeType = () => {
  const { goToNextScreen, incomeStatementPath, setDataModal } = useContext(TemplateDeclarationContext)
  const { onSubmit, isButtonDisabled, options, handlerSelectOption } = useExtraIncomeType(
    goToNextScreen,
    incomeStatementPath,
    setDataModal
  )

  return (
    <TemplateDeclaration subTitle="Outras Rendas">
      <Container>
        <FormContainer>
          <Form onSubmit={onSubmit}>
            <Title text="Quais outras rendas você recebeu?" />
            <SubTitle>Você pode selecionar mais de uma opção.</SubTitle>
            <SubTitle isBold>
              Lembre-se de considerar somente rendas que você recebeu <br />
              no mês de {getPreviousMonthName()}
            </SubTitle>

            <ContainerOptions>
              {options &&
                options.length > 0 &&
                options.map(option => <Option key={option.id} option={option} handlerSelectOption={handlerSelectOption} />)}
            </ContainerOptions>

            <ButtonV3 marginHorizontal={0} buttonProps={{ type: 'submit' }} disabled={isButtonDisabled} text="Avançar" />
          </Form>
        </FormContainer>
      </Container>
    </TemplateDeclaration>
  )
}
